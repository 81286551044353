// Google Tag Manager Class to be injected
// import Cookies from 'js-cookie'

// TODO: check if port to https://nuxt.com/modules/nuxt-gtm is possible

import type { RouteLocationNormalized } from '#vue-router';
// import type { RouteLocationNormalized } from '~/.nuxt/vue-router';
import type {
    CheckoutMutation_ManageCheckout_Checkout,
    CheckoutMutation_ManageCheckout_Checkout_Extended,
    CheckoutMutation_ManageCheckout_Checkout_Extended_LineItem,
    CheckoutMutation_ManageCheckout_Checkout_Extended_LineItems,
    GTMCartItem,
    GTMEventName,
    GTMEventPayload,
} from '~/@types/nestedGraphqlTypes';
import { CurrencyCode } from '~/graphql/generated';

import type { GTMModuleOptions } from '~/modules/gtm';

const searchEngines = [
    'www.google',
    'www.bing',
    'www.yandex',
    'search.yahoo',
    'duckduckgo',
    'www.ask',
    'www.search',
    'www.info',
    'www.baidu',
    'search.aol',
    'www.dogpile',
];
// let firstCall = false

type CTXType = any; //

// only executed client side
const getNativeVariantId = (id: string) => {
    return atob(id).substring('gid://shopify/ProductVariant/'.length);
};

const getNativeProductId = (id?: string | null) => {
    return id?.substring('gid://shopify/Product/'.length) || '';
};

type EcommerceItem = (
    | {
          item_id: string;
          item_name?: string;
      }
    | {
          item_id?: string;
          item_name: string;
      }
) & {
    affiliation?: string;
    discount?: number;
    index?: number;
    item_brand?: string;
    item_category?: string;
    item_category2?: string;
    item_category3?: string;
    item_category4?: string;
    item_category5?: string;
    item_list_id?: string;
    item_list_name?: string;
    item_variant?: string;
    location_id?: string;
    price?: number;
    quantity?: number;
};

type GA4SelectItemPayload = {
    item_list_id?: string;
    item_list_name?: string;
    items: EcommerceItem[];
};

export type GA4SelectContentPayload = { content_type: string; content_id: string } & Record<string, string | number>;

// TODO: udate with tracking-helper stuff
export class GTM {
    ctx: CTXType;
    options: GTMModuleOptions;
    constructor(ctx: CTXType, options: GTMModuleOptions) {
        this.ctx = ctx;
        this.options = options;
    }

    init() {
        // @ts-ignore
        window[this.options.layer] = window[this.options.layer] || [];
        this.logData('gtm init started');
        this.pushEvent({
            event: 'gtm.js',
            'gtm.start': new Date().getTime(),
        });

        if (this.options.pageTracking && (!this.options.respectDoNotTrack || !hasDNT())) {
            this.logData('do page tracking');
            this.initPageTracking();
        }
        /*
    if (!firstCall) {
      firstCall = true
      window[this.options.layer].push({ event: 'gtm.js', pageType: 'PageView', pageUrl: path, routeName: name })
    }
    */
    }

    ecommerceClear() {
        this.pushEvent({ ecommerce: null, event: 'ecommerceClear' });
    }

    numericPrice(p?: number | string, fallback = 0) {
        if (typeof p === 'string') {
            try {
                return Number.parseFloat(p);
            } catch (err) {}
        }
        return p || fallback;
    }

    /**
     * returns the categories as an array
     */
    getCategories(cat: string | null | undefined): FixedLengthArray<string, 5> {
        if (!cat) {
            return ['', '', '', '', ''];
        }
        const c = cat.toLowerCase();
        if (['original', 'now', 'up', 'off', 'explore'].includes(c)) {
            return ['Bikes', cat, '', '', ''];
        }
        return [cat, '', '', '', ''];
    }

    ecommerceProductList(listId: string, listLabel: string, products: Array<CMSProduct>) {
        if (process.server) {
            return;
        }
        // console.log('list id', listId, listLabel, products); // eslint-disable-line no-console
        if (Array.isArray(products) && products.length > 0) {
            const items = [];
            for (const product of products) {
                const price = product.priceNumeric;
                const currency: CurrencyCode = product.currency || CurrencyCode.Eur;
                // console.log('product', product);
                // pick the first variant
                const variant = product.variants.items[0];
                const cats = this.getCategories(product.productType);
                items.push({
                    /* eslint-disable camelcase */
                    item_name: product.title, // Name or ID is required.
                    item_id: variant?.sku,
                    currency,
                    price: product.priceNumeric ? product.priceNumeric : 0,
                    index: items.length,
                    item_brand: 'woom',
                    item_category: cats[0],
                    item_category2: cats[1],
                    item_category3: cats[2],
                    item_category4: cats[3],
                    item_category5: cats[4],
                    item_variant: variant?.title === 'Default Title' ? '' : variant?.title,
                    item_list_id: listId,
                    item_list_name: listLabel,
                    quantity: 1,
                    /* eslint-enable camelcase */
                });
            }
            this.ecommerceClear();
            this.pushEvent({
                event: 'view_item_list',
                ecommerce: {
                    /* eslint-disable camelcase */
                    item_list_id: listId,
                    item_list_name: listLabel,
                    items,
                    /* eslint-enable camelcase */
                },
            });
        }
    }

    parseTextPrice(price: string) {
        let bundlePrice = price.replace(/[^0-9.,]/gi, '');
        const dot = bundlePrice.indexOf('.');
        const comma = bundlePrice.indexOf(',');
        if (comma > 0) {
            if (dot > 0 && dot < comma) {
                bundlePrice = bundlePrice.replace(/[.]/gi, '').replace(/[,]/, '.');
            } else if (dot > 0 && dot > comma) {
                bundlePrice = bundlePrice.replace(/[,]/gi, '');
            } else {
                bundlePrice = bundlePrice.replace(/[,]/gi, '.');
            }
        }
        try {
            const ret = Number.parseFloat(bundlePrice);
            return Number.isNaN(ret) ? 0 : ret;
        } catch (err) {
            return 0;
        }
    }

    // STILL TODO
    ecommerceBundlePage(page: PageByUrlQuery_PageByUrl_Page | undefined, products: PageByUrlQuery_PageByUrl_Page_Bundle_Products) {
        // console.log('comm bundle page', page, products);
        const price = this.parseTextPrice(page?.bundle?.price || '');

        // console.log('price is', price);

        if (page && page.bundle) {
            // parse the price label from bundle

            // console.log('ecom gtm page view', page, product, variant)
            if (products && products.length > 0) {
                const first = products[0];
                const currency = first.variants.items.find((x: any) => x).price.currencyCode || 'USD';
                // console.log('first product', first, currency)
                const cats = ['Bundle', '', '', '', ''];
                const shid = page.bundle.code;
                this.ecommerceClear();
                // this will be a single product call for the bundle
                let img = null;
                const imgBase = page.bundle.cartImage?.data.image || '';
                if (imgBase !== '') {
                    img = `${page.bundle.cartImage?.data.baseUrl}${imgBase}`;
                }
                const bundleLabel = page.bundle.label || 'Undefined Bundle';

                this.pushEvent({
                    event: 'productView',
                    ecommerce: {
                        currency,
                        detail: {
                            actionField: { list: 'Product Detail Page' }, // Optional list property.
                            products: [
                                {
                                    /* eslint-disable camelcase */
                                    name: bundleLabel, // Name or ID is required.
                                    id: shid,
                                    price: `${price.toFixed(2)}`,
                                    brand: 'woom',
                                    category: 'Bundle',
                                    item_variant: '',
                                    image: img,
                                    categories: cats, // [],
                                    /* eslint-enable camelcase */
                                },
                            ],
                        },
                    },
                    shopProductId: shid,
                });

                this.ecommerceClear();
                // now ga4 - this will contain the breakdown with all bundle products
                const items = [];
                let idx = 0;
                for (const product of products) {
                    const variant = product.variants.items.find((x: any) => x) || null;
                    // console.log('type is', product, variant.productType)
                    const cats = this.getCategories(variant.productType);
                    if (variant) {
                        items.push({
                            /* eslint-disable camelcase */
                            item_name: product.shopTitle, // Name or ID is required.
                            item_id: variant.sku,
                            currency,
                            price: this.numericPrice(variant.price.amount),
                            index: idx,
                            bundle_name: bundleLabel,
                            bundle_price: price,
                            barcode: variant.barcode,
                            item_brand: 'woom',
                            item_category: cats[0],
                            item_category2: cats[1],
                            item_category3: cats[2],
                            item_category4: cats[3],
                            item_category5: cats[4],
                            item_variant: '',
                            /* eslint-enable camelcase */
                        });
                        idx += 1;
                    }
                }
                this.pushEvent({
                    event: 'view_item',
                    ecommerce: {
                        currency,
                        value: price,
                        items,
                    },
                    shopProductId: shid,
                });

                this.pushEvent({
                    event: 'emarsys_bundle',
                    emarsys: {
                        category: this.calcBreadcrumbs(page),
                    },
                });
            }
        }
    }

    calcBreadcrumbs(page: PageByUrlQuery_PageByUrl_Page) {
        if (page.breadcrumbs) {
            return page.breadcrumbs
                ?.map((b: any) => {
                    return b.breadcrumb?.replace(/(%\(|\)%|%{|}%|%\[|\]%)/gi, '');
                })
                .join(' > ');
        }
        return '';
    }

    ecommerceContentPage(page: PageByUrlQuery_PageByUrl_Page | undefined) {
        if (page) {
            this.pushEvent({
                event: 'emarsys_content',
                emarsys: {
                    category: this.calcBreadcrumbs(page),
                },
            });
        }
    }

    ecommerceProductPage(page: PageByUrlQuery_PageByUrl_Page | undefined) {
        // let's only execute client side
        if (process.server) {
            return;
        }
        if (page && page.product) {
            const product = page.product;
            const price = product.priceRange?.minVariantPrice.amount || '';
            const currency = product.priceRange?.minVariantPrice.currencyCode || CurrencyCode.Eur;
            const variant = product.variants?.items?.find((x: any) => x) || null;

            // console.log('ecom gtm page view', page, product, variant)
            if (product && variant) {
                this.ecommerceClear();
                const cats = this.getCategories(product.productType);
                // does not exist - but sku does on variant?
                // let shid = product.sku
                let shid = variant.sku;
                if (product.nativeId) {
                    // we operate on client side - rather use atob
                    shid = atob(product.nativeId);
                    shid = shid.substring(22);
                    // console.log('product', product.nativeId, shid);
                }
                this.pushEvent({
                    event: 'productView',
                    ecommerce: {
                        currency,
                        detail: {
                            actionField: { list: 'Product Detail Page' }, // Optional list property.
                            products: [
                                {
                                    /* eslint-disable camelcase */
                                    name: product.shopTitle, // Name or ID is required.
                                    id: variant.sku,
                                    price,
                                    brand: 'woom',
                                    category: product.productType,
                                    item_variant: variant.shopTitle === 'Default Title' ? '' : variant.shopTitle,
                                    image: product.shopImage,
                                    categories:
                                        product.collections && product.collections.length > 0
                                            ? product.collections.map((c) => {
                                                  return c?.handle;
                                              })
                                            : [],
                                    /* eslint-enable camelcase */
                                },
                            ],
                        },
                    },
                    shopProductId: shid,
                });
                this.ecommerceClear();
                // now ga4
                this.pushEvent({
                    event: 'view_item',
                    ecommerce: {
                        currency,
                        value: this.numericPrice(price),
                        items: [
                            {
                                /* eslint-disable camelcase */
                                item_name: product.shopTitle, // Name or ID is required.
                                item_id: variant.sku,
                                currency,
                                price: this.numericPrice(price),
                                index: 0,
                                barcode: variant.barcode,
                                item_brand: 'woom',
                                item_category: cats[0],
                                item_category2: cats[1],
                                item_category3: cats[2],
                                item_category4: cats[3],
                                item_category5: cats[4],
                                item_variant: variant.shopTitle === 'Default Title' ? '' : variant.shopTitle,
                                /* eslint-enable camelcase */
                            },
                        ],
                    },
                });
                this.pushEvent({
                    event: 'emarsys_pdp',
                    emarsys: {
                        category: this.calcBreadcrumbs(page),
                    },
                });
            }
        }
    }

    logData(d: any) {
        console.log(JSON.stringify(d, null, '  ')); // eslint-disable-line no-console
    }

    // product parameter here is going to be messy later...
    ecommerceListingClick(product: CMSProduct, position = 0, listId = 'undefined', listLabel = 'undefined List') {
        // console.log('ecom gtm click', product, position + 1)
        if (product) {
            const variant = product.variants.items[0];
            this.ecommerceClear();
            this.pushEvent({
                event: 'productClick',
                ecommerce: {
                    currency: product.currency,
                    click: {
                        actionField: { list: 'Search Results' }, // Optional list property.
                        products: [
                            {
                                /* eslint-disable camelcase */
                                name: product.title, // Name or ID is required.
                                id: variant.sku,
                                price: `${variant.priceNumeric}`,
                                brand: 'woom',
                                category: product.productType,
                                item_variant: variant.title,
                                position,
                                /* eslint-enable camelcase */
                            },
                        ],
                    },
                },
            });
            const cats = this.getCategories(product.productType);
            const ecommerce = {
                item_list_id: listId,
                item_list_name: listLabel,
                items: [
                    {
                        item_name: product.title, // Name or ID is required.
                        item_id: variant.sku,
                        currency: product.currency,
                        price: variant.priceNumeric,
                        index: position,
                        item_brand: 'woom',
                        item_category: cats[0],
                        item_category2: cats[1],
                        item_category3: cats[2],
                        item_category4: cats[3],
                        item_category5: cats[4],
                        item_variant: variant.title,
                        // item_list_id: listId,
                        // item_list_name: listLabel,
                    },
                ],
            };
            this.selectItem(ecommerce);
        }
    }

    // STILL TODO
    processCartItem(li: CheckoutMutation_ManageCheckout_Checkout_Extended_LineItem, idx: number, quantity: number): GTMCartItem {
        // console.log('line item is', li);
        const cats = this.getCategories(li.variant?.productType);
        let bundleName = '';
        let bundlePrice = 0;
        const bundlePriceDef = li.customAttributes.find((p: any) => p.key === '_bundle_price');
        if (bundlePriceDef) {
            bundlePrice = this.parseTextPrice(bundlePriceDef.value);
        }
        const handle = li.variant?.productRef ? li.variant.productRef.substring(li.variant.productRef.lastIndexOf(':') + 1) : '';
        const bundleNameDef = li.customAttributes.find((p: any) => p.key === 'Bundle');
        if (bundleNameDef) {
            bundleName = bundleNameDef.value;
        }
        let discount = 0;
        // TODO will have to change with the new storefront cart api
        if (Array.isArray(li.discountAllocations)) {
            for (const d of li.discountAllocations) {
                if (d.discountedAmount && d.discountedAmount.amount) {
                    const p = this.parseTextPrice(d.discountedAmount.amount);
                    if (p) {
                        discount += p / (li.quantity || 1);
                    }
                }
            }
            discount = Math.round(discount * 100) / 100;
        }
        const price = this.numericPrice(li.variant?.price?.amount || '');
        const productID = getNativeProductId(li.nativeProductId);
        return {
            /* eslint-disable camelcase */
            item_name: li.shopTitle, // Name or ID is required.
            item_id: li.variant?.sku,
            currency: li.variant?.price?.currencyCode,
            price: price,
            priceString: typeof price === 'string' ? price : price?.toFixed(2),
            discount,
            quantity,
            image: li.variant?.shopImage,
            sku: li.variant?.sku || '',
            index: idx,
            bundle_name: bundleName,
            bundle_price: bundlePrice,
            barcode: li.variant?.barcode,
            item_brand: 'woom',
            item_category: cats[0],
            item_category2: cats[1],
            item_category3: cats[2],
            item_category4: cats[3],
            item_category5: cats[4],
            item_variant: li.variant?.shopTitle === 'Default Title' ? '' : li.variant?.shopTitle,
            /** for klaviyo */
            handle,
            product_type: li.variant?.productType || 'undefined type',
            product_id: productID,
            product_title: li.shopTitle || 'undefined title',
            variant_id: li.variant?.nativeId ? getNativeVariantId(li.variant?.nativeId) : '',
            /* eslint-enable camelcase */
        };
    }

    viewCart(cart: CheckoutMutation_ManageCheckout_Checkout) {
        // console.log('view cart', cart);
        this.ecommerceClear();
        if (cart) {
            const currency = cart.totalPrice?.currencyCode || CurrencyCode.Usd;
            const items = [];
            let idx = 0;
            for (const li of cart.lineItems) {
                // is this right? use 1 if quantity is missing?
                const quantity = li.quantity || 1;
                items.push(this.processCartItem(li, idx, quantity));
                idx += 1;
            }
            this.pushEvent({
                event: 'view_cart',
                ecommerce: {
                    currency,
                    value: this.numericPrice(cart.totalPrice?.amount || 0),
                    items,
                },
            });
        }
    }

    cartPage() {
        this.pushEvent({
            event: 'emarsys_cart',
            emarsys: {
                category: 'Cart',
            },
        });
    }

    // we only deal in single quantities when items are added
    addCartItems(items: CheckoutMutation_ManageCheckout_Checkout_Extended_LineItems, checkout: any, componentOrigin?: string) {
        // console.log('add cart items', items)
        let checkoutId = checkout && checkout.nativeCheckoutId ? checkout.nativeCheckoutId.substring('gid://shopify/Checkout/'.length) : checkout.id;
        if (Array.isArray(items) && items.length > 0) {
            let price = 0;
            const currency = items[0].variant?.price?.currencyCode || '';
            let idx = 0;
            const newItems: GTMCartItem[] = [];
            const oldItems: GTMCartItem[] = [];
            for (const i of items) {
                const data = this.processCartItem(i, idx, 1);
                const numericPrice = this.numericPrice(data.price);
                // console.log('item', data.item_name, data.price, data.discount)
                if (typeof numericPrice === 'number') {
                    let discount = 0;
                    if (data.discount) {
                        discount = typeof data.discount === 'string' ? parseInt(data.discount) : data.discount;
                    }
                    price += numericPrice - discount;
                }
                newItems.push(data);
                oldItems.push({
                    /* eslint-disable camelcase */
                    name: data.item_name,
                    item_variant: data.item_variant,
                    id: data.item_id,
                    price: typeof numericPrice === 'number' ? `${numericPrice.toFixed(2)}` : data.price,
                    discount: `${typeof data.discount === 'number' ? data.discount.toFixed(2) : data.discount}`,
                    brand: 'woom',
                    category: i.variant?.productType,
                    quantity: 1,
                    /* eslint-enable camelcase */
                });
                idx += 1;
            }
            // adding all total items
            const allItems: GTMCartItem[] = [];
            idx = 0;
            for (const i of checkout.lineItems) {
                allItems.push(this.processCartItem(i, idx, 1));
                idx += 1;
            }
            // console.log('price is', price);
            this.ecommerceClear();
            this.pushEvent({
                event: 'addToCart',
                ecommerce: {
                    currency,
                    add: {
                        // 'add' actionFieldObject measures.
                        products: oldItems,
                    },
                },
            });
            this.ecommerceClear();
            const lineItemPrice = checkout?.lineItemsSubtotalPrice ? this.numericPrice(checkout.lineItemsSubtotalPrice.amount) : 0;
            const total = checkout?.totalPrice ? this.numericPrice(checkout.totalPrice.amount) : 0;
            let discount = 0;
            if (typeof lineItemPrice === 'number' && typeof total === 'number') {
                discount = Math.round((lineItemPrice - total + Number.EPSILON) * 100) / 100;
            }
            // console.log('checkout', checkout);
            const gaEvent: GTMEventPayload = {
                event: 'add_to_cart',
                ecommerce: {
                    currency,
                    value: price,
                    items: newItems,
                    all: allItems,
                    basket_size: checkout.totalQuantity,
                    basket_value: total,
                    basket_total_discount: discount,
                    basket_original_total_price: lineItemPrice,
                    checkoutUrl: checkout.webUrl,
                    component_origin: componentOrigin,
                },
            };
            // console.log('add cart items', gaEvent, checkout);
            this.pushEvent(gaEvent);
        }
        if (items && items.length === 1) {
            const productID = getNativeProductId(items[0].nativeProductId);
            this.pushEvent({
                event: 'add_triple_whale',
                tw: {
                    item: productID,
                    q: 1,
                    token: checkoutId,
                },
            });
        }
    }

    // we only deal with single quantities when items are removed
    removeCartItems(items: CheckoutMutation_ManageCheckout_Checkout_Extended_LineItems) {
        if (Array.isArray(items) && items.length > 0) {
            let price = 0;
            const currency = items[0].variant?.price?.currencyCode || '';
            let idx = 0;
            const newItems: GTMCartItem[] = [];
            const oldItems: GTMCartItem[] = [];
            for (const i of items) {
                const data = this.processCartItem(i, idx, 1);
                const numericPrice = this.numericPrice(data.price);
                // console.log('item', data.item_name, data.price, data.discount)
                if (typeof numericPrice === 'number') {
                    price += numericPrice - (typeof data.discount === 'string' ? parseInt(data.discount) : data.discount || 0);
                }
                newItems.push(data);
                oldItems.push({
                    name: data.item_name,
                    item_variant: data.item_variant,
                    id: data.item_id,
                    price: typeof numericPrice === 'number' ? `${numericPrice.toFixed(2)}` : data.price,
                    discount: `${typeof data.discount === 'number' ? data.discount.toFixed(2) : data.discount}`,
                    brand: 'woom',
                    category: i.variant?.productType,
                    quantity: 1,
                });
                idx += 1;
            }
            // console.log('price is', price);
            this.ecommerceClear();
            this.pushEvent({
                event: 'removeFromCart',
                ecommerce: {
                    currency,
                    remove: {
                        // 'add' actionFieldObject measures.
                        products: oldItems,
                    },
                },
            });
            this.ecommerceClear();
            this.pushEvent({
                event: 'remove_from_cart',
                ecommerce: {
                    currency,
                    value: price,
                    items: newItems,
                },
            });
            console.log('remove cart items', price, newItems);
        }
    }

    getWindowLayer() {
        // @ts-ignore
        return window[this.options.layer];
    }

    initPageTracking() {
        this.logData('gtm: init page tracking');
        const router = useRouter();
        // const route = useRoute();
        router.afterEach(async (to: RouteLocationNormalized, _from: RouteLocationNormalized) => {
            await wait();
            // console.log('this', this)

            // const controller = route.name || null;
            // console.log('router afterEach', language, region, to.gtm, to.fullPath, to.name, document.referrer) // eslint-disable-line no-console
            const referrer = document.referrer;
            let organic = false;
            if (referrer) {
                const referrerHostname = referrer.split('/')[2];
                const shortenedHostname = referrerHostname.substr(0, referrerHostname.lastIndexOf('.'));
                const indexOfSearchEngines = searchEngines.indexOf(shortenedHostname);
                if (indexOfSearchEngines > -1) {
                    organic = true;
                }
            }
            this.logData('gtm pageview');
            this.pushEvent(
                (to as any).gtm || {
                    event: 'nuxtRoute',
                    pageType: 'PageView',
                    pageUrl: to.fullPath,
                    routeName: to.name,
                    referrer,
                    bot: !organic,
                },
            );
        });
    }

    updateCart(objIn: CheckoutMutation_ManageCheckout_Checkout_Extended | null, eventName?: GTMEventName) {
        const obj: CheckoutMutation_ManageCheckout_Checkout_Extended = objIn || {
            cart: {
                /* eslint-disable camelcase */
                items: [],
                basket_size: 0,
                basket_value: 0,
                currency: null,
                /* eslint-enable camelcase */
            },
        };
        obj.event = eventName || 'cart_update';

        if (objIn) {
            this.pushEvent({
                event: 'cart-clear',
                cart: null,
            });
            obj.cart = {
                items: (obj.lineItems || []).map((lineItem, index) => this.processCartItem(lineItem, index, lineItem.quantity || 1)),
                basket_size: obj.totalQuantity,
                basket_value: obj.totalPrice?.amount ? this.numericPrice(obj.totalPrice.amount) : 0,
                currency: obj.totalPrice ? obj.totalPrice.currencyCode : null,
            };
        }
        this.pushEvent(obj);
    }

    /**
     * Triggers GA4 standard event `select_content`
     *
     * 📘 [Event docs](https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm#select_content)
     */
    selectContent(data: GA4SelectContentPayload) {
        this.pushEvent({
            event: 'select_content',
            ...data,
        });
    }
    /**
     * Triggers GA4 standard event `select_item`
     *
     * 📘 [Event docs](https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm#select_item)
     */
    selectItem(ecommerce: GA4SelectItemPayload) {
        this.ecommerceClear();
        this.pushEvent({
            event: 'select_item',
            ecommerce,
        });
    }

    pushEvent(obj: GTMEventPayload) {
        try {
            if (!this.getWindowLayer()) {
                throw new Error('missing GTM dataLayer');
            }
            if (typeof obj !== 'object') {
                throw new TypeError('event should be an object');
            }
            if (!obj.ecommerce) {
                if (!obj.event) {
                    throw new Error('missing event property');
                }
            }
            this.getWindowLayer().push(obj);
        } catch (err) {
            console.error('[ERROR] [GTM]', err);
        }
    }
}

export default defineNuxtPlugin((nuxtApp) => {
    const ctx: any = {};
    // in order to access the gtm config client side (including env variables), we need to access it via the
    // public configuration in the runtime config
    // since we don't want any server side calls to be performed, we need to limit each method to only execute client side
    const config = useRuntimeConfig();

    const options: GTMModuleOptions = config.public?.gtm;
    console.log('gtm get config', config);
    if (!options) {
        console.log('gtm load - no options present: abort');
        return;
    }

    // console.log('gtm options', options)
    if (config.public.nodeEnv !== 'production' && !options.dev) {
        console.log('gtm - neither production nor a options.dev flag');
        return;
    }

    if (process.server) {
        console.log("gtm don't execute server side");
        return;
    }

    console.log('inject gtm');
    const $gtm = new GTM(ctx, options);

    nuxtApp.provide('gtm', $gtm);

    $gtm.init();
    // get the current route
});
